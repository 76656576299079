import React, {Component} from 'react'


class Truncate extends Component {
	constructor(props) {
		super(props);
		// console.log(props);
	}

	render() {
		var str = this.props.str
		var length = this.props.length
		var ending = this.props.ending



		if (length == null) {
			length = 100;
		}
		if (ending == null) {
			ending = '...';
		}
		if (str.length > length) {
			return str.substring(0, length - ending.length) + ending;
		} else {
			return str;
		}

	}


}

export default Truncate
