import React from 'react'
import { Link } from 'gatsby'
import { has } from 'lodash'
import Truncate from '../PageHelpers/truncate'

function heroBackground (data) {
  let css
  let addOverlay

  if (has(data, 'hero_settings.css')) {
    var gradient = data.hero_settings.css
    gradient = gradient.split('~').map(s => s.split(','))
    css = 'linear-gradient(' + gradient[0][0] + ',' + gradient[0][1] + ')'
  } else {
    css = 'linear-gradient(#5e2eae,#3162b6)'
  }

  if (data.cover) {
    css = 'url(' + data.cover + ') center no-repeat'
    addOverlay = 'background-image-holder'
  }

  let figureStyle = {
    background: css,
    opacity: 1,
  }
  // console.log(figureStyle)
  return (
    <figure className={`image is-3by2 ${addOverlay}`} style={figureStyle} aria-label={`Image used for ${data.title}`} />
  )
}

const PostCard = ({ posts }) => {
  // console.log({ posts })

  return (
    <div className='columns is-multiline'>
      {posts.map(({ node: post }) => (
        <div className='column is-3' key={post.id}>

          <div className='card'>
            <Link aria-label={`Keep Reading ${post.frontmatter.title}`} to={post.fields.slug} >
              <div className='card-image' data-overlay={5}>
                <div>
                  <p className='title'>
                    <Truncate str={post.frontmatter.title} length={28} ending={'...'} />
                  </p>
                </div>
                {heroBackground(post.frontmatter)}
              </div>
              <div className='card-content'>
                <p className={`date`}>{post.frontmatter.date}</p>
                <p className='subtitle'>{post.excerpt}</p>
              </div>
            </Link>
          </div>

        </div>
      ))}
    </div>
  )
}

export default PostCard
