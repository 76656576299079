import React, {Component} from 'react'
import Helmet from 'react-helmet'
import {Link, graphql} from 'gatsby'
import { has } from 'lodash'
import PostCard from '../components/PostCard'
import config from '../../data/config'
import HeroBanner from '../components/Hero'

class ExampleRoute extends Component {
  render () {
    const example_pages = this.props.data.allMarkdownRemark.edges
    const example_tag = this.props.pageContext.example_tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader__ = `${totalCount} post${
      totalCount === 1 ? '' : 's'
    } tagged with “${example_tag}”`

    const tagHeader = `${example_tag}`

    const websiteSchemaOrgJSONLD = {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: config.siteUrl,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    }

    var example_colors = [
      ['#010038', '#293a80', '#537ec5', '#f39422'],
      ['#fff4e4', '#f88020', '#d1274b', '#3d0e1e'],
      ['#f35588', '#ffbbb4', '#71a95a', '#007944'],
      ['#39375b', '#745c97', '#d597ce', '#f5b0cb'],
    ]

    const hero_settings = {
      size: 'is-medium',
      particle: true,
      images: '',
      css: '#ed1846,#ed1846~#ed1846,#ed1846',
      font_color: '#fff',
    }

    return (
      <div>
        <Helmet>
          <title>Examples | Layer Cake Solutions</title>
          {/* Schema.org tags */}
          <script type='application/ld+json'>
            {JSON.stringify(websiteSchemaOrgJSONLD)}
          </script>
        </Helmet>

        <HeroBanner
          title={tagHeader}
          subtitle={'examples im here'}
          hero_settings={hero_settings}
        />

        <section className='section is-feature-grey edge--top'>
          <div className='container'>
            <PostCard posts={example_pages} />
            <div className='buttons is-centered'>
              <Link to='/examples/' className='button is-rounded'>Browse all example tags</Link>
            </div>
          </div>
        </section>
      </div>

    )
  }
}

export default ExampleRoute

export const examplePageQuery = graphql`
    query ExamplePage($example_tag: String) {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            limit: 1000
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { example_tags: { in: [$example_tag] } } }
        ) {
            totalCount
            edges {
                node {
                    excerpt(pruneLength: 90)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        templateKey
                        cover
                        date(formatString: "MMMM DD, YYYY")
                        example_tags
                        hero_settings { 
                          size
                          particle
                          images
                          css
                          font_color
                          title_align
                        }
                    }
                }
            }
        }
    }
`
